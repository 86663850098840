<template>
  <div>
    <van-nav-bar
      :title="dataList.appName"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main">
      <nut-video
        :sources="sources"
        :options="options"
        @play="play"
        @pause="pause"
        @playend="playend"
        style="width: 90%; margin: 0.06rem auto"
      >
      </nut-video>
      <p class="btn" @click="clickHandler">进入体验</p>
      <h4>{{ dataList.appName }}</h4>
      <p
        style="text-indent: 2em; padding: 0 0.1rem"
        v-html="dataList.introduction"
      ></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: Object,
    isShow: Object,
    stateSkip: String,
  },
  data() {
    return {
      sources: [
        {
          src: "",
          type: "video/mp4",
        },
      ],
      options: {
        controls: true,
        options: "",
      },
    };
  },
  created() {
    if (this.dataList.videoUrl) {
      this.sources[0].src = this.dataList.videoUrl;
      this.options.options = this.dataList.imgUrl;
    }
  },
  methods: {
    onClickLeft() {
      this.isShow.show = false;
    },
    play(elm) {
      console.log("play", elm);
    },
    pause(e) {
      console.log("pause");
    },
    playend(e) {
      alert("播放结束");
    },
    clickHandler() {
      console.log(123);
      window.location.href = this.dataList.experienceNow;
    },
  },
};
</script>

<style lang="less" scoped>
h4 {
  padding: 0.1rem;
  margin: 0.1rem;
  font-size: 0.3rem;
}
p {
  padding: 0.1rem;
  margin: 0.1rem;
  font-size: 0.26rem;
}
.btn {
  text-align: center;
  button {
    color: #fff;
  }
  /deep/ .nut-button {
    background-color: #ccc;
  }
}
</style>