<template>
  <div class="det">
    <div v-if="!isShow.show">
      <van-nav-bar
        :title="$t(stateSkip)"
        left-arrow
        @click-left="onClickLeft"
      />
      <div>
        <ul>
          <li v-for="(item, index) in list" :key="index">
            <img :src="item.imgUrl" alt="" @click="onImgs1(item)" />
            <!-- <h5>{{ item.introduction }}</h5> -->
            <p>{{ item.introduction }}</p>
          </li>
        </ul>
      </div>
    </div>
    <dataList
      :dataList="dataList"
      :stateSkip="stateSkip"
      :isShow="isShow"
      v-if="isShow.show"
    ></dataList>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dataList from "./dataList.vue";
export default {
  computed: {
    ...mapState({ stateSkip: (state) => state.skip }),
  },
  components: {
    dataList,
  },
  data() {
    return {
      list: null,
      dataList: null,
      iconData: "play", //视频按钮icon
      isShow: {
        show: false,
      },
    };
  },
  created() {
    if (this.stateSkip.length != 0) {
      // 获取数据
      this.getList();
    } else {
      this.$router.push("/feiyi");
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go("-1");
    },
    getList() {
      let abbreviation = JSON.parse(
        sessionStorage.getItem("abbreviation")
      ).travelAbbreviation;
      let params = new FormData();
      let prefix = "/api/apt/";
      let src = {
        AR趣玩: "getAr",
        线上展馆: "getOnLineExhibitionhall",
        游戏互动: "getInteractiveGames",
        云裳非遗: "getCloudReward",
      };
      params.append("abbreviation", abbreviation);
      this.$axios.post(prefix + src[this.stateSkip], params).then((res) => {
        this.list = res.data;
      });
    },
    onVideo() {
      if (this.iconData == "play") {
        this.iconData = "pause";
      } else {
        this.iconData = "play";
      }
    },
    onImgs1(val) {
      this.dataList = val;
      this.isShow.show = true;
    },
  },
};
</script>

<style lang="less" scoped>
.det {
  padding-top: 0.96rem;
  margin-bottom: 9vh;
}

/deep/ .van-nav-bar {
  width: 100%;
  position: fixed;
  top: 0;
  i {
    font-size: 0.5rem;
    color: #000;
    margin-top: -0.01rem;
  }
}

/** 戏曲内容 */
ul {
  width: 100%;
  padding: 0.3rem;
  li {
    position: relative;
    margin-bottom: 0.4rem;
    img {
      width: 100%;
      height: 3.34rem;
      border-radius: 0.12rem;
    }
    h5 {
      font-size: 0.28rem;
      font-weight: 400;
      color: #666666;
      padding: 0 0.2rem;
      margin-top: 0.1rem;
    }
    p {
      font-size: 0.2rem;
      color: #999999;
      padding: 0 0.2rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .grade {
      width: 1.2rem;
      line-height: 0.42rem;
      text-align: center;
      color: #fff;
      font-size: 0.2rem;
      background: #ab3435;
      border-radius: 0.12rem 0 0.12rem 0;
      position: absolute;
      top: 0;
      left: 0;
    }
    .sort {
      width: 1.2rem;
      line-height: 0.42rem;
      text-align: center;
      color: #fff;
      font-size: 0.2rem;
      background: #716160;
      border-radius: 0 0.12rem 0 0.12rem;
      position: absolute;
      top: 0;
      right: 0;
    }
    .amount {
      width: 100%;
      line-height: 0.6rem;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      border-radius: 0 0 0.12rem 0.12rem;
      padding-right: 0.3rem;
      text-align: right;
      font-size: 0.2rem;
      color: #fff;
      position: absolute;
      bottom: 19%;

      /deep/ .van-rate__icon {
        font-size: 0.3rem;
      }
      span {
        margin-left: 0.2rem;
      }
    }
  }
  li:last-child {
    margin: 0;
  }
}
</style>